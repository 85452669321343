import { Component, NgZone } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var poligono:any;
declare var gtag:any;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(   private _route:Router){

    const navEndEvents$ = this._route.events
.pipe(
  filter(event => event instanceof NavigationEnd)
)


navEndEvents$.subscribe((event: NavigationEnd) => {
  gtag('config', 'G-V466CSKFV4', {
    'page_path': event.urlAfterRedirects
  });
});



  }
}
